import React, {Fragment, useState, useRef} from 'react';

import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './AsyncAutocomplete.scss';

function AsyncAutocomplete(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const ref = useRef();

    const handleSearch = async (query) => {
        setIsLoading(true);

        const options = await props.handleAutocompleteSearch(query);

        setOptions(options);
        setIsLoading(false);
    };

    const clearAutocomplete = () => {
        ref.current.clear();
        props.onAutocompleteClear();
    };

    return (
        <>
            <AsyncTypeahead
                ref={ref}
                style={{width: props.width || 'auto'}}
                id={props.id}
                isLoading={isLoading}
                labelKey={props.labelKey}
                minLength={1}
                onSearch={handleSearch}
                onChange={(val) => props.onChange(val[0])}
                options={options}
                placeholder={props.placeholder}
                renderMenuItemChildren={(option, props) => (
                    <Fragment>
                        <span>{option.name}</span>
                    </Fragment>
                )}
            />
            {props.fieldValue && props.showClear ? (
                <InputGroup.Append>
                    <Button variant='secondary' onClick={clearAutocomplete}>
                        {props.clearButtonLabel}
                    </Button>
                </InputGroup.Append>
            ) : null}
        </>
    );
}

export default AsyncAutocomplete;
