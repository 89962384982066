import React from 'react';
import queryString from 'query-string';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {injectIntl} from 'react-intl';

import ColumnContainer from './ColumnContainer';
import API from '../../common/utils/API';

import LoadingButton from '../../components/form/LoadingButton';

import * as FeatherIcons from 'react-icons/fi';

class ResetPasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            password: '',
            password_confirmation: '',
            error: null,
            errors: {},
        };
    }

    get token() {
        return queryString.parse(this.props.location.search).token;
    }

    handleLoginFormSubmit = async (e) => {
        e.preventDefault();
        if (!this.validate()) {
            return;
        }
        this.setState({
            loading: true,
            error: '',
        });
        try {
            await API.session.resetPassword(this.token, this.state.password);
            this.setState({
                success: true,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
        this.setState({
            loading: false,
        });
    };

    validate() {
        this.setState({errors: {}});
        if (this.state.password.length < 8) {
            this.setState({
                errors: {
                    password: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_MIN_LENGTH',
                    }),
                },
            });
            return false;
        }
        if (this.state.password.length > 24) {
            this.setState({
                errors: {
                    password: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_MAX_LENGTH',
                    }),
                },
            });
            return false;
        }
        if (!/(?=.*[a-z])/g.test(this.state.password)) {
            this.setState({
                errors: {
                    password: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_LOWERCASE',
                    }),
                },
            });
            return false;
        }
        if (!/(?=.*[A-Z])/g.test(this.state.password)) {
            this.setState({
                errors: {
                    password: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_UPPERCASE',
                    }),
                },
            });
            return false;
        }
        if (!/(?=.*[0-9])/g.test(this.state.password)) {
            this.setState({
                errors: {
                    password: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_NUMERIC',
                    }),
                },
            });
            return false;
        }
        if (this.state.password !== this.state.password_confirmation) {
            this.setState({
                errors: {
                    password_confirmation: this.props.intl.formatMessage({
                        id: 'AUTH.FORGOT.VALIDATION_MATCHING',
                    }),
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <ColumnContainer>
                <Form onSubmit={this.handleLoginFormSubmit}>
                    <Form.Group>
                        <h3 className='text-dark'>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.RESET_PASSWORD_TITLE',
                            })}
                        </h3>
                        <hr />
                        <p>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.RESET_PASSWORD_INSTRUCTIONS',
                            })}
                        </p>
                    </Form.Group>

                    <Alert
                        variant='danger'
                        onClose={() => this.setState({error: null})}
                        dismissible
                        show={this.state.error}
                    >
                        <FeatherIcons.FiAlertCircle
                            size='20'
                            className='mb-3'
                        />
                        <p className='mb-0'>{this.state.error}</p>
                    </Alert>

                    {this.state.success ? (
                        <>
                            <Alert variant='success'>
                                <FeatherIcons.FiCheck
                                    size='20'
                                    className='mb-3'
                                />
                                <p className='mb-0'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'AUTH.FORGOT.RESET_PASSWORD_SUCCESS',
                                    })}
                                </p>
                            </Alert>
                            <Button
                                variant='secondary'
                                className='d-block px-0'
                                href='/login'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'AUTH.LOGIN.LOGIN_BUTTON',
                                })}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Form.Group controlId='formBasicPassword'>
                                <Form.Label>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'AUTH.FORGOT.RESET_PASSWORD_PASSWORD',
                                    })}
                                </Form.Label>
                                <Form.Control
                                    type='password'
                                    value={this.state.password}
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }
                                    error={this.state.errors.password}
                                    isInvalid={this.state.errors.password}
                                />
                                {this.state.errors.password ? (
                                    <Form.Control.Feedback type='invalid'>
                                        {this.state.errors.password}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId='formBasicPassword'>
                                <Form.Label>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'AUTH.FORGOT.RESET_PASSWORD_PASSWORD_CONFIRMATION',
                                    })}
                                </Form.Label>
                                <Form.Control
                                    type='password'
                                    value={this.state.password_confirmation}
                                    onChange={(e) =>
                                        this.setState({
                                            password_confirmation:
                                                e.target.value,
                                        })
                                    }
                                    isInvalid={
                                        this.state.errors.password_confirmation
                                    }
                                />
                                {this.state.errors.password_confirmation ? (
                                    <Form.Control.Feedback type='invalid'>
                                        {
                                            this.state.errors
                                                .password_confirmation
                                        }
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>

                            <LoadingButton
                                variant='primary'
                                type='submit'
                                loading={this.state.loading}
                                block
                                className='mt-5'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'AUTH.FORGOT.RESET_PASSWORD_TITLE',
                                })}
                            </LoadingButton>
                        </>
                    )}
                </Form>
            </ColumnContainer>
        );
    }
}

export default injectIntl(ResetPasswordContainer);
