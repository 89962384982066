import React from 'react';
import {Redirect} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import API from '../../common/utils/API';
import ColumnContainer from './ColumnContainer';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as FeatherIcons from 'react-icons/fi';
import LoadingButton from '../../components/form/LoadingButton';

class ForgotPasswordContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: '',
            error: null,
            shouldRedirectToSuccess: false,
        };
    }

    handleLoginFormSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        API.session
            .forgotPassword(this.state.email)
            .then((response) => {
                this.setState({
                    shouldRedirectToSuccess: true,
                });
            })
            .catch((e) => {
                this.setState({
                    error: {},
                    isLoading: false,
                });
            });
    };

    render() {
        return this.state.shouldRedirectToSuccess ? (
            <Redirect to='/password-reset-link-sent' />
        ) : (
            <ColumnContainer>
                <Form onSubmit={this.handleLoginFormSubmit}>
                    <Form.Group>
                        <h3 className='text-dark'>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.FORGOT_PASSWORD_TITLE',
                            })}
                        </h3>
                        <hr />
                        <p>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.FORGOT_PASSWORD_INSTRUCTIONS',
                            })}
                        </p>
                    </Form.Group>
                    <Alert
                        variant='danger'
                        onClose={() => this.setState({error: null})}
                        dismissible
                        show={this.state.error}
                    >
                        <FeatherIcons.FiAlertCircle
                            size='20'
                            className='mb-3'
                        />
                        <Alert.Heading as='h5'>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.GENERIC_ERROR_TITLE',
                            })}
                        </Alert.Heading>
                        <p className='mb-0'>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.GENERIC_ERROR_MESSAGE',
                            })}
                        </p>
                    </Alert>
                    <Form.Group controlId='formBasicEmail'>
                        <Form.Label>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.FORGOT.FORGOT_PASSWORD_EMAIL',
                            })}
                        </Form.Label>
                        <Form.Control
                            type='email'
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'AUTH.FORGOT.FORGOT_PASSWORD_EMAIL_FIELD_PLACEHOLDER',
                            })}
                            value={this.state.email}
                            onChange={(e) =>
                                this.setState({email: e.target.value})
                            }
                        />
                    </Form.Group>
                    <LoadingButton
                        variant='primary'
                        type='submit'
                        loading={this.state.loading}
                        block
                    >
                        {this.props.intl.formatMessage({
                            id: 'AUTH.FORGOT.FORGOT_PASSWORD_SUBMIT_BUTTON',
                        })}
                    </LoadingButton>
                </Form>
            </ColumnContainer>
        );
    }
}

export default injectIntl(ForgotPasswordContainer);
