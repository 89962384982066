import React from 'react';
import {Switch} from 'react-router-dom';

import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import RoleList from './RoleList';
import CreateRole from './CreateRole';
import RoleDetail from './RoleDetail';
import EditRole from './EditRole';

import withLayout from '../../components/ui/Layout';

function Roles() {
    const intl = useIntl();

    return (
        <Switch data-test='rolesComponent'>
            <PrivateRoute
                permission='ROLES_INDEX'
                exact
                path='/roles'
                component={withLayout(
                    RoleList,
                    intl.formatMessage({id: 'ROUTES.ROLES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='ROLES_CREATE'
                exact
                path='/roles/create'
                component={withLayout(
                    CreateRole,
                    intl.formatMessage({id: 'ROUTES.ROLES.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='ROLES_SHOW'
                exact
                path='/roles/:id'
                component={withLayout(
                    RoleDetail,
                    intl.formatMessage({id: 'ROUTES.ROLES.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='ROLES_EDIT'
                exact
                path='/roles/:id/edit'
                component={withLayout(
                    EditRole,
                    intl.formatMessage({id: 'ROUTES.ROLES.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default Roles;
