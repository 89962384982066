import React from 'react';
import {connect} from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {store} from '../common/redux/StoreReducers';
import {useIntl} from 'react-intl';
import Image from 'react-bootstrap/Image';
import SpanishFlagImage from './ui/assets/es-flag.svg';
import EnglishFlagImage from './ui/assets/en-flag.svg';
import './LanguageSelector.css';

const languages = [
    {
        lang: 'en',
        name: 'LANG.ENGLISH',
        image: EnglishFlagImage,
    },
    {
        lang: 'es',
        name: 'LANG.SPANISH',
        image: SpanishFlagImage,
    },
];

function LanguageSelector(props) {
    const intl = useIntl();
    const {lang} = props;
    const currentLanguage = languages.find((x) => x.lang === lang);
    return (
        <NavDropdown
            className='top-navbar-language-dropdown'
            title={
                <>
                    <Image src={currentLanguage.image} rounded width='17' />
                </>
            }
            id='basic-nav-dropdown'
            alignRight
        >
            {languages.map((language, key) => (
                <NavDropdown.Item
                    key={key}
                    onClick={() => {
                        store.dispatch(props.switchLanguage(language.lang));
                    }}
                >
                    <Image
                        src={language.image}
                        rounded
                        width='18'
                        className='mr-2'
                    />{' '}
                    {intl.formatMessage({id: language.name})}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}

const mapStateToProps = (state) => ({
    lang: state.langReducer.lang,
});

const switchLanguage = function (lang) {
    return {
        type: 'SWITCH_LANGUAGE',
        payload: {
            lang: lang,
        },
    };
};

const mapDispatchToProps = {
    switchLanguage: switchLanguage,
};
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
