const initialState = {
    title: '',
};

export function testReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_HEADER_TITLE':
            return Object.assign({}, state, {
                title: action.title,
                content: action.content,
            });
        default:
            return state;
    }
}
