import {API} from '../API';
import Permission from '../../models/Permission';

const permissions = {
    get: async () => {
        const {data: response} = await API.get(`/permissions`);

        return {
            permissions: response.data.map(
                (permission) => new Permission(permission),
            ),
        };
    },
};

export default permissions;
