import {combineReducers} from 'redux';
import {testReducer} from './store_reducers/TestReducer';
import {sessionReducer} from './store_reducers/SessionReducer';
import {langReducer} from './store_reducers/LangReducer';

export const rootReducer = combineReducers({
    sessionReducer,
    testReducer,
    langReducer,
});
