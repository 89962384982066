import React from 'react';
import {useIntl} from 'react-intl';
import Image from 'react-bootstrap/Image';
import forbiddenImage from '../components/ui/assets/403-forbidden.svg';
import './Forbidden.scss';

function Forbidden() {
    const intl = useIntl();

    return (
        <>
            <div id='forbidden-container'>
                <div className='box'>
                    <div className='text-container'>
                        <h1 className='mb-0'>
                            {intl.formatMessage({id: 'ERROR_PAGES.403.TITLE'})}
                        </h1>
                        <p>
                            {intl.formatMessage({id: 'ERROR_PAGES.403.TEXT'})}
                        </p>
                    </div>
                    <div className='image-container'>
                        <Image src={forbiddenImage} fluid />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Forbidden;
