import {API} from '../API';
import User from '../../models/User';
import Permission from '../../models/Permission';

const session = {
    login: (email, password) =>
        new Promise((resolve, reject) => {
            return API.post(`/auth/login`, {
                provider: 'email',
                user: email,
                secret: password,
            })
                .then((response) => {
                    response.data.data.user.permissions = response.data.data.user.permissions.map(
                        (permission) => new Permission(permission),
                    );
                    let user = new User(response.data.data.user);
                    resolve({
                        token: response.data.data.token,
                        refreshToken: response.data.data.refreshToken,
                        expiresAt: response.data.data.expiresAt,
                        user: user,
                    });
                })
                .catch((err) => reject(err));
        }),
    forgotPassword: (email) =>
        new Promise((resolve, reject) => {
            return API.post(`/auth/forgot`, {
                email: email,
            })
                .then((response) => {
                    resolve({
                        messages: response.data.messages,
                    });
                })
                .catch((err) => reject(err));
        }),
    resetPassword: async (token, password) => {
        await API.post(`/auth/reset`, {
            token,
            password,
        });
        return true;
    },
    verify: (token) =>
        new Promise((resolve, reject) => {
            return API.post(`/auth/email-verification`, {
                token: token,
            })
                .then((response) => {
                    resolve({
                        messages: response.data.messages,
                    });
                })
                .catch((err) => reject(err));
        }),
};

export default session;
