import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Forbidden from '../containers/Forbidden';

const PrivateRoute = ({
    permission,
    permissions,
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return rest.isLoggedIn ? (
                    permission === undefined ||
                    rest.isSuperAdmin ||
                    permissions.find(
                        (grantedPermission) =>
                            grantedPermission.code === permission,
                    ) ? (
                        <Component {...props} />
                    ) : (
                        <Forbidden />
                    )
                ) : (
                    <Redirect
                        to={{pathname: '/login', state: {from: props.location}}}
                    />
                );
            }}
        />
    );
};

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.sessionReducer.isLoggedIn,
        isSuperAdmin: state.sessionReducer.user
            ? state.sessionReducer.user.isSuperAdmin
            : false,
        permissions: state.sessionReducer.isLoggedIn
            ? state.sessionReducer.user.permissions
            : [],
    };
};

export default connect(mapStateToProps)(PrivateRoute);
