import React from 'react';
import {injectIntl} from 'react-intl';
import Image from 'react-bootstrap/Image';
import API from '../../common/utils/API';
import LoadingButton from '../../components/form/LoadingButton';
import queryString from 'query-string';
import activateAccountErrorImage from '../../components/ui/assets/activate-account-error.svg';
import activateAccountSuccessImage from '../../components/ui/assets/activate-account-success.svg';
import activateAccountImage from '../../components/ui/assets/activate-account.svg';
import './ActivateAccount.scss';

class AccountActivation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: queryString.parse(this.props.location.search).token,
            shouldDisplayActivationError: false,
            shouldDisplaySuccessfulActivationMessage: false,
        };
    }

    handleAccountActivation = () => {
        API.session
            .verify(this.state.token)
            .then((response) => {
                this.setState({
                    shouldDisplaySuccessfulActivationMessage: true,
                });
            })
            .catch((e) => {
                this.setState({
                    shouldDisplayActivationError: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <>
                <div id='activation-container'>
                    <div className='box'>
                        <div className='text-container'>
                            <h1>
                                {this.state.shouldDisplayActivationError
                                    ? this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.VERIFICATION_ERROR_TITLE',
                                      })
                                    : this.state
                                          .shouldDisplaySuccessfulActivationMessage
                                    ? this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.SUCCESSFUL_VERIFICATION_TITLE',
                                      })
                                    : this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.VERIFY_YOUR_ACCOUNT_TITLE',
                                      })}
                            </h1>
                            <p>
                                {this.state.shouldDisplayActivationError
                                    ? this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.VERIFICATION_ERROR_TEXT',
                                      })
                                    : this.state
                                          .shouldDisplaySuccessfulActivationMessage
                                    ? this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.SUCCESSFUL_VERIFICATION_TEXT',
                                      })
                                    : this.props.intl.formatMessage({
                                          id:
                                              'AUTH.ACTIVATION.VERIFY_YOUR_ACCOUNT_TEXT',
                                      })}
                            </p>
                            {!this.state.shouldDisplayActivationError &&
                            !this.state
                                .shouldDisplaySuccessfulActivationMessage ? (
                                <LoadingButton
                                    onClick={this.handleAccountActivation}
                                    loading={this.state.loading}
                                    variant='primary'
                                    size='lg'
                                    block
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            'AUTH.ACTIVATION.VERIFY_YOUR_ACCOUNT_BUTTON_LABEL',
                                    })}
                                </LoadingButton>
                            ) : null}
                        </div>
                        <div className='image-container'>
                            <Image
                                src={
                                    this.state.shouldDisplayActivationError
                                        ? activateAccountErrorImage
                                        : this.state
                                              .shouldDisplaySuccessfulActivationMessage
                                        ? activateAccountSuccessImage
                                        : activateAccountImage
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(AccountActivation);
