import {setBearerToken} from '../../utils/API';

const initialState = {
    isLoggedIn: false,
    user: undefined,
    session: {},
};

export function sessionReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN':
            setBearerToken(action.payload.token);
            return Object.assign({}, state, {
                isLoggedIn: true,
                user: action.payload.user,
                session: {
                    token: action.payload.token,
                    refreshToken: action.payload.refreshToken,
                },
            });
        case 'LOGOUT':
            return Object.assign({}, state, {
                isLoggedIn: false,
                user: null,
                session: null,
            });
        case 'UPDATE_MEDIA':
            const newState = {...state};

            newState.user.pictureUrl = action.payload.media;

            return newState;
        default:
            return state;
    }
}
