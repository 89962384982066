import React from 'react';
import {connect} from 'react-redux';

class Can extends React.Component {
    check = () => {
        return (
            this.props.isSuperAdmin ||
            this.props.permissions.find(
                (permission) => permission.code === this.props.run,
            )
        );
    };

    render() {
        return <>{this.check() ? this.props.children : null}</>;
    }
}

const mapStateToProps = function (state) {
    return {
        permissions: state.sessionReducer.user.permissions,
        isSuperAdmin: state.sessionReducer.user.isSuperAdmin,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Can);
