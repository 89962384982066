import React from 'react';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';

import enMessages from './messages/en';
import esMessages from './messages/es';

const allMessages = {
    en: enMessages,
    es: esMessages,
};

const mapStateToProps = (state) => ({
    lang: state.langReducer.lang,
});

function I18nProvider(props) {
    const locale = props.lang;
    const messages = allMessages[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            {props.children}
        </IntlProvider>
    );
}

export default connect(mapStateToProps)(I18nProvider);
