import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

const AuthRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !rest.isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{pathname: '/', state: {from: props.location}}}
                    />
                )
            }
        />
    );
};

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.sessionReducer.isLoggedIn,
    };
};

export default connect(mapStateToProps)(AuthRoute);
